import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSubscriptionInfoStore = defineStore('subscriptionInfoStore', () => {
  const subscriptionInfo = ref<any>({})


  const setSubscriptionInfo = (data) => {
    subscriptionInfo.value = data
  }

  return {
    subscriptionInfo,
    setSubscriptionInfo
  }
})
